import React from "react";

const JamToast = ({ visible, header, children, color = "bg-warning" }) => {
  return (
    <div className="toast-container toast-bottom-right">
      <div
        className={visible ? "toast fade show" : "toast fade "}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-autohide="true"
        id="toastjam"
      >
        <div className={`toast-header ${color}`}>
          <span className="czi-announcement text-white mr-2" aria-hidden="true"></span>
          <strong className="mr-auto text-white"> {header}</strong>

          <button type="button" className="close text-white" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body bg-white text-black" style={{ minHeight: 10 + "vh" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default JamToast;
