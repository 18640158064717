import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { CartContext } from "../cartContext";

const Item = ({ item, addToCart, decreaseAmount, removeItem, disable }) => {
  const initialState = 1;
  const [quantity, setQuantity] = useState(initialState);
  let selected = quantity > initialState;

  const inc = () => {
    if (!disable) {
      const newQuantity = quantity + 1;
      const newItem = {
        id: item.id,
        name: item.name,
        price: item.price,
        quantity: newQuantity,
      };
      addToCart(newItem);
      setQuantity(newQuantity);
    }
  };
  const desc = () => {
    const newQuantity = quantity - 1;
    // const newItem = { id: item.id, name: item.name, price: item.price, quantity: newQuantity };
    newQuantity < 2 ? removeItem(item.id) : decreaseAmount(item.id);
    setQuantity(newQuantity);
    if (selected) setQuantity(quantity - 1);
  };

  return (
    <li className="cz-filter-item d-flex justify-content-between align-items-center mb-1 my-1 p-1 border-bottom">
      <div className="d-flex flex-column w-100">
        <button
          disabled={disable}
          type="button"
          onClick={() => inc()}
          className="btn btn-sm d-flex justify-content-between"
        >
          {item.name}
          <strong className="ml-3">
            {item.price + 6}
            <span className="small">€</span>
          </strong>
        </button>
        {/* Ligne basse */}
        {selected && (
          <div className="d-flex justify-content-center">
            <div className="">
              <button
                disabled={disable}
                type="button "
                onClick={() => desc()}
                className={selected ? "btn badge" : "btn badge invisible"}
              >
                <i className="czi-arrow-down "></i>
              </button>
              <span className="ml-2 mr-1">
                <small className={selected ? "" : "invisible"}>
                  {quantity} ltr
                </small>
              </span>
              <button
                disabled={disable}
                type="button"
                onClick={() => inc()}
                className={selected ? "btn badge" : "btn badge invisible"}
              >
                <i className="czi-arrow-up"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

function PunschSelection({ onChange, prodid }) {
  const initialState = [];
  const [cart, setCart] = useState(initialState);
  const { inCart } = useContext(CartContext);
  const [inzeCart, setInzeCart] = useState(false);

  useEffect(() => {
    onChange(cart);
    setInzeCart(inCart(prodid));
    return () => {};
  }, [cart]);

  const addToCart = (newitem) => {
    const { id, name, price, quantity } = newitem;
    const item = [...cart].find((item) => item.id === id);
    if (item) {
      if (quantity === 1) removeItem(id);
      else increaseAmount(id);
      return;
    } else {
      const adjustedPrice = price + 6;
      const newItem = {
        id,
        name,
        quantity,
        price: adjustedPrice,
      };
      const newCart = [...cart, newItem];
      setCart(newCart);
    }
  };
  const removeItem = (id) => {
    setCart([...cart].filter((item) => item.id !== id));
  };
  const increaseAmount = (id) => {
    const newCart = [...cart].map((item) => {
      return item.id === id
        ? { ...item, quantity: item.quantity + 1 }
        : { ...item };
    });
    setCart(newCart);
  };
  const decreaseAmount = (id) => {
    const newCart = [...cart].map((item) => {
      return item.id === id
        ? { ...item, quantity: item.quantity - 1 }
        : { ...item };
    });
    setCart(newCart);
  };

  const data = useStaticQuery(graphql`
    query {
      hasura {
        categories_by_pk(id: 3) {
          id
          name
          products(order_by: { subcatBysubCat: { id: asc } }) {
            name
            id
            price
            image
            description
            subcatBysubCat {
              name
            }
          }
        }
      }
    }
  `);

  const items = data?.hasura?.categories_by_pk.products.map((p, i) => {
    return (
      <Item
        key={`item+${i}`}
        item={p}
        addToCart={addToCart}
        decreaseAmount={decreaseAmount}
        removeItem={removeItem}
        disable={inzeCart}
      />
    );
  });

  return (
    <div className="p-2 my-2">
      <div className="mb-1 border-bottom">
        <strong className="mr-2 ">wähle aus 18 Sorten</strong>
        <small className="ml-4 text-muted">min 2ltr</small>
      </div>

      <ul
        className="widget-list cz-filter-list list-unstyled"
        style={{ maxHeight: 20 + "rem" }}
        data-simplebar
        data-simplebar-auto-hide="false"
      >
        {items}
      </ul>
    </div>
  );
}

export default PunschSelection;
