import React, { useContext, useEffect, useState } from "react";

import { CartContext } from "../cartContext";
import ImageSm from "./ImageSm";

const OptionList = ({ items, text }) => {
  const splitie = items.split(" - ");

  return (
    <ul className="list-unstyled font-size-sm">
      <li className="d-flex align-items-center">
        <i className="czi-check-circle text-success mr-1"></i>
        <span className="font-size-ms">{text}</span>
      </li>
      {splitie.map((e, i) => (
        <OptionItem el={e} key={`optl${i}`} />
      ))}
    </ul>
  );
};
const OptionItem = ({ el }) => {
  return (
    <li className="d-flex align-items-center">
      <i className="czi-check-circle text-success mr-1"></i>
      <span className="font-size-ms">{el}</span>
    </li>
  );
};

function OptionsBox(props) {
  const { inCart } = useContext(CartContext);
  const product = props.prod;
  const { productsOptions } = props.prod;
  const { handleClick } = props;
  const { selectedOption } = props;
  const [option, setOption] = useState(0);
  const [subOption, setSubOption] = useState(0);
  const [subList, setSubList] = useState(
    productsOptions[0].option.productsOptions
  );
  const [text, setText] = useState(
    productsOptions[0].option.productsOptions[0].subOption.description
  );
  const isInCart = inCart(props.prod.id);
  const handleChange = (e, i) => {
    if (!isInCart) {
      setSubList(() => e.option.productsOptions);
      setOption(i);
      setText(
        productsOptions[i].option.productsOptions[subOption].subOption
          .description
      );
      const newOption =
        productsOptions[i].option.productsOptions[subOption].subOption;
      handleClick(newOption);
    }
  };
  const handleChangeSelect = (evt) => {
    if (!isInCart) {
      setSubOption(Number(evt.target.value));
      setText(subList[evt.target.value].subOption.description);
      const newOption =
        productsOptions[option].option.productsOptions[Number(evt.target.value)]
          .subOption;
      handleClick(newOption);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      productsOptions.map((p, i) => {
        p.option.productsOptions.map((o, id) => {
          if (o.subOption.id === selectedOption.id) {
            setOption(i);
            setSubList(() => p.option.productsOptions);
            setSubOption(id);
          }
          return 0;
        });
        return 0;
      });
    }
  }, []);

  useEffect(() => {}, [option, subOption]);
  return (
    <div className="d-flex flex-wrap align-items-center pt-2 pb-2 ">
      <div className="bg-secondary rounded  w-100 p-3 text-center">
        <span className="d-inline-block h6 mb-0 mr-1">{`${productsOptions.length}`}</span>
        <span className="h6 ">Optionen</span>
        <ImageSm
          filename={product?.productsOptions[option].option.image}
          className="rounded d-block d-sm-none"
          alt={product?.productsOptions[option].option.name}
          caption={product?.productsOptions[option].option.name}
        />
        <div className="d-flex justify-content-center">
          {productsOptions.map((e, i) => (
            <button
              key={`button${i}`}
              disabled={isInCart}
              onClick={() => handleChange(e, i)}
              className={[
                isInCart === i
                  ? "btn btn-shadow btn-sm mr-2 "
                  : "btn btn-sm btn-shadow mr-2 ",
                i < productsOptions.length - 1 ? " mt-2 mb-2 " : " mt-2 mb-2 ",
                option === i
                  ? " btn-primary active "
                  : " btn-outline-secondary ",
              ]}
            >
              {/* <span className={option === i ? "czi-check text-white mr-2" : " mr-2"}></span> */}
              {e.option.name}
            </button>
          ))}
        </div>
      </div>

      {/* How many guests */}
      <div className="d-flex justify-content-center w-100">
        <select
          disabled={isInCart}
          className="custom-select"
          onChange={(e) => {
            handleChangeSelect(e);
          }}
          style={{ maxWidth: 20 + "rem" }}
        >
          {subList.map((s, i) => {
            return (
              <option
                key={`option${i}`}
                defaultValue={i === subOption}
                value={i}
              >
                {s.subOption.name} - {s.subOption.price}€ -{" "}
                {(s.subOption.price / s.subOption.guests).toFixed(2)}€ pro
                Person
              </option>
            );
          })}
        </select>
      </div>
      <div className="bg-secondary rounded  w-100 p-3 mb-2">
        {productsOptions.map((e, i) => (
          <div
            className={option === i ? "collapse show" : "collapse"}
            key={`po${i}`}
          >
            <h5 className="">Inhalt der Box</h5>
            <OptionList items={e.option.description} text={text} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OptionsBox;
