import React, { useContext, useEffect, useState } from "react";

import CarouselPictures from "./carousels/CarouselPictures";
import { CartContext } from "../cartContext";
import Gallery from "./Gallery";
import JamToast from "./JamToast";
import { Link } from "gatsby";
import Marmelades from "./Marmelades";
import OptionsBox from "../components/OptionsBox";
import PunschSelection from "./PunschSelection";
import TurboSelection from "./TurboSelection";

function ProductDetails({ product }) {
  const {
    addToCart,
    increaseAmount,
    decreaseAmount,
    inCart,
    getMarmelades,
    getItem,
    getOptions,
  } = useContext(CartContext);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectMarmelades, setSelectMarmelades] = useState([0, 0]);
  const [totalPunsches, settotalPunsches] = useState(0);
  const [selectPunsches, setSelectPnsches] = useState([]);
  const [totalTurbos, settotalTurbos] = useState(0);
  const [selectTurbos, setSelectTurbos] = useState([]);
  const [jamAlert, setJamAlert] = useState(false);
  const [punschAlert, setPunschAlert] = useState(false);
  const [addtocartAlert, setAddtocartAlert] = useState(false);
  const [images, setimages] = useState([]);
  const [captions, setcaptions] = useState([]);
  const isInCart = inCart(product?.id);
  const amount = getItem(product?.id);
  const hasOptions = product?.productsOptions.length > 0;
  const optionInCart = getOptions(product?.id);
  const poster = product?.poster;
  let marmelades = product?.categoryByCategory.marmeladesByCategories;
  let ingredients = product?.ingredients;
  let slug = "";
  let categoryName = product?.categoryByCategory.name;
  let categoryDescription = product?.categoryByCategory.description;
  let categoryLongText = product?.categoryByCategory.longtext;
  let description = product?.description;
  let flaschenInfo = product?.allergenes;
  const hasMarmelades = getMarmelades(product?.id);
  const hasIngredients = product?.hasOwnProperty("ingredients");
  const getLowestPrice = (product) => {
    let lowestPrice = 0;
    let prices = [];
    if (product?.productsOptions.length > 0) {
      product?.productsOptions.map((p) => {
        p.option.productsOptions.map((s) => {
          let priPerGuest = s.subOption.price / s.subOption.guests;
          prices.push(priPerGuest);
        });
      });
    }
    lowestPrice = prices.sort((a, b) => a - b)[0];
    return lowestPrice.toFixed(2);
  };
  let price = `${product?.price}€`;
  if (hasOptions) price = `ab ${getLowestPrice(product)}€ pro Person`;
  if (product?.categoryByCategory.id === 4)
    price = `ab ${product?.price + 12}€`;
  const handleClick = (e) => {
    setSelectedOption(e);
  };
  const handleClickMarmelades = (e) => {
    setSelectMarmelades(e);
  };
  const Ingredients = ({ ingredients }) => {
    const ingredientsSplit = hasIngredients ? ingredients.split(" - ") : null;
    let title = "";
    if (product?.categoryByCategory.id === 4) title = "Inhalt der Box"; // pusnch-box
    if (product?.categoryByCategory.id === 3) title = "Zutaten"; // punsch-flaschen
    if (product?.categoryByCategory.id === 2) title = ""; // boissons-fr
    if (product?.categoryByCategory.id === 1) title = "Toppings"; // crepes-box

    return ingredientsSplit ? (
      <div className="bg-secondary rounded  w-100 p-3 mb-2">
        <h5>{title}</h5>
        <ul className="list-unstyled font-size-sm">
          {ingredientsSplit.map((e, i) => (
            <li key={`pe${i}`} className="d-flex align-items-center">
              <i className="czi-check-circle text-success mr-1"></i>
              <span className="font-size-ms">{e}</span>
            </li>
          ))}
        </ul>
      </div>
    ) : (
      <div></div>
    );
  };

  useEffect(() => {
    // WebpackError: TypeError: Cannot read property 'split' of undefined
    marmelades = product?.categoryByCategory.marmeladesByCategories;
    slug = product?.categoryByCategory.slug;
    categoryName = product?.categoryByCategory.name;
    ingredients = product?.ingredients;
    let imgsplit = product?.image.split(",");
    setimages(imgsplit.length > 0 ? imgsplit : [product?.image]);
    if (product?.captions !== null) {
      let captionsplit = product?.captions.split(",");
      setcaptions(captionsplit.length > 0 ? captionsplit : [product?.captions]);
    }

    // ----------------------------------------------
    if (hasMarmelades.length > 0) {
      setSelectMarmelades(hasMarmelades);
    }
    if (hasOptions && !optionInCart) {
      setSelectedOption(
        product?.productsOptions[0].option.productsOptions[0].subOption
      );
    }
  }, []);

  //remove punsch toast after xxx ms
  useEffect(() => {
    setTimeout(() => {
      setPunschAlert(false);
      setJamAlert(false);
      setAddtocartAlert(false);
    }, 3000);

    return () => {};
  }, [punschAlert, jamAlert, addtocartAlert]);
  const checkCart = (product, selectedOption, selectMarmelades) => {
    // check if marmelades have been selected
    const sm = selectMarmelades.reduce((a, b) => a + b);

    if (marmelades.length > 1) {
      if (sm >= 2) {
        addToCart(
          product,
          selectedOption,
          selectMarmelades,
          selectPunsches,
          totalPunsches,
          selectTurbos,
          totalTurbos
        );
        setAddtocartAlert(true);
      } else {
        setJamAlert(true);
      }
    } else {
      if (product?.categoryByCategory.id === 4) {
        if (totalPunsches === 0) {
          setPunschAlert(true);
        } else {
          addToCart(
            product,
            selectedOption,
            selectMarmelades,
            selectPunsches,
            totalPunsches,
            selectTurbos,
            totalTurbos
          );
          setAddtocartAlert(true);
        }
      } else {
        addToCart(
          product,
          selectedOption,
          selectMarmelades,
          selectPunsches,
          totalPunsches,
          selectTurbos,
          totalTurbos
        );
        setAddtocartAlert(true);
      }
    }
  };

  const onChangePunsch = (value) => {
    setSelectPnsches(value);
    return 0;
  };
  // update totalpunsches when one pusnch is added/removed
  useEffect(() => {
    let newTotal = selectPunsches.reduce((total, cartItem) => {
      return (total += cartItem.quantity * cartItem.price);
    }, 0);

    settotalPunsches(newTotal);
    return () => {};
  }, [selectPunsches]);

  const onChangeTurbo = (value) => {
    setSelectTurbos(value);
    return 0;
  };
  // update totalturbos when one pusnch is added/removed
  useEffect(() => {
    let newTotal = selectTurbos.reduce((total, cartItem) => {
      return (total += cartItem.quantity * cartItem.price);
    }, 0);
    settotalTurbos(newTotal);
    return () => {};
  }, [selectTurbos]);

  return (
    <div>
      <JamToast header="Marmeladen nicht selected" visible={jamAlert}>
        Select one of our super nice marmeladen
      </JamToast>
      <JamToast header="Punsch not selected" visible={punschAlert}>
        Select at least one sort of punsch
      </JamToast>
      <JamToast
        header="Item added to cart"
        color={"bg-success"}
        visible={addtocartAlert}
      >
        {product?.name} has been added to your cart
      </JamToast>
      {/* <!-- Page Content--> */}
      {/* <!-- Shadow box--> */}

      <section className="pb-3">
        <div className="bg-light box-shadow-lg rounded-lg overflow-hidden">
          <div className="row">
            {/* <!-- Content--> */}
            <section className="col-sm-7 col-md-7 col-xl-6 pt-2 pt-lg-4 ">
              <div className="pt-2 px-1 pr-lg-0 pl-xl-5">
                {/* <!-- Product gallery--> */}
                {/* Disabled on mobile, replaced by slider */}
                <div className=" d-none d-sm-block">
                  <Gallery images={images} captions={captions} />
                </div>
                {/* slider displayed on mobile */}
                <div className="d-block d-sm-none">
                  <CarouselPictures
                    images={images}
                    captions={captions}
                    group={`${product?.id}`}
                  />
                </div>
                {/* <!-- Wishlist + Sharing--> */}
                <div className="d-flex flex-wrap justify-content-between align-items-center border-top mt-3"></div>
              </div>
              <section className="d-none d-sm-block pl-xl-5">
                <ul className="nav nav-tabs ">
                  <li className="nav-item">
                    <a
                      className="nav-link p-4 active"
                      href="#details"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="category details"
                      aria-selected="true"
                    >
                      {categoryName}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link p-4"
                      href="#mehr"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="mehr infos"
                      aria-selected="true"
                    >
                      Mehr Infos
                    </a>
                  </li>
                </ul>
                <div className="tab-content pt-2">
                  {/* <!-- Product details tab--> */}
                  <div
                    className="tab-pane fade show active"
                    id="details"
                    role="tabpanel"
                    aria-labelledby="category-tab"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="font-size-md">{categoryDescription}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="mehr"
                    role="tabpanel"
                    aria-labelledby="mehr-tab"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="font-size-md">{categoryLongText}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            {/* <!-- Sidebar--> */}
            <aside className="col-sm-5 col-md-5 col-xl-6 pt-4 pb-3">
              <h1 className="h2">{product?.name}</h1>
              <Link
                className="product-meta d-block font-size-sm pb-2"
                to={`/produkte/${slug}`}
              >
                {categoryName}
              </Link>

              <div className="h3 font-weight-normal text-accent">{price}</div>

              <hr className="" />
              <div className="ml-auto mt-3 mb-2">{description}</div>
              <div className="ml-auto mb-3">
                {hasOptions ? (
                  <OptionsBox
                    handleClick={handleClick}
                    prod={product}
                    selectedOption={optionInCart}
                  />
                ) : null}
                {ingredients.length > 1 ? (
                  <Ingredients ingredients={ingredients} />
                ) : null}
                {marmelades.length > 0 ? (
                  <Marmelades
                    handleClick={handleClickMarmelades}
                    selectMarmelades={hasMarmelades}
                    marmelades={marmelades}
                    id={product?.id}
                  />
                ) : null}
                {/* IF pusnchbox categpory show it */}
                {product?.categoryByCategory.id === 4 ? (
                  <PunschSelection onChange={onChangePunsch} id={product?.id} />
                ) : null}
                {product?.categoryByCategory.id === 4 ||
                product?.categoryByCategory.id === 3 ? (
                  <TurboSelection onChange={onChangeTurbo} id={product?.id} />
                ) : null}
                {/* total for punsches box */}
                {selectTurbos.length > 0 || selectPunsches.length > 0 ? (
                  <div className="mt-4 p-2 bg-secondary rounded ">
                    <div>
                      {selectPunsches.map((e, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between mr-2"
                            key={`p${i}`}
                          >
                            <span className="">
                              {e.quantity} ltr. {e.name}
                            </span>
                            <span>
                              {e.price * e.quantity}
                              <span className="small"> €</span>
                            </span>
                          </div>
                        );
                      })}
                      {selectTurbos.map((e, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between mr-2"
                            key={`p${i}`}
                          >
                            <span className="">
                              <small>
                                {e.quantity}x {e.name}
                              </small>
                            </span>
                            <span>
                              <small>
                                {e.price * e.quantity}
                                <span className="small"> €</span>
                              </small>
                            </span>
                          </div>
                        );
                      })}
                      <hr className="mt-2" />
                      <div className="d-flex justify-content-between mr-2  cz-filter-item-text mt-2">
                        <span className="text-strong">
                          <strong>Total</strong>
                        </span>
                        <span>
                          <strong>
                            {totalPunsches + totalTurbos}{" "}
                            <span className="small">€</span>
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {flaschenInfo && (
                  <div className="mt-3">
                    <strong className="h6">{flaschenInfo}</strong>
                  </div>
                )}

                <div className="d-flex justify-content-between mt-0 pt-3 align-self-end">
                  <p className="font-size-md text-danger">
                    {`${
                      product?.id > 9999
                        ? ""
                        : product?.id > 1999 && product?.id < 9999
                        ? " Lieferung in 48 Std."
                        : "Lieferung in 2 Std."
                    }`}
                  </p>
                </div>
                <div className="d-flex justify-content-between mt-0 pt-3  align-self-end">
                  {isInCart && (
                    <button
                      type="button"
                      className="btn btn-primary btn-shadow btn-icon"
                      onClick={() => {
                        decreaseAmount(product?.id, amount);
                      }}
                    >
                      <i className="czi-arrow-down"></i>
                    </button>
                  )}
                  <button
                    className="btn active btn-primary  btn-block"
                    type="submit"
                    disabled={isInCart}
                    onClick={() => {
                      checkCart(product, selectedOption, selectMarmelades);
                    }}
                  >
                    {!isInCart ? (
                      <i className="czi-cart font-size-lg mr-2"></i>
                    ) : null}
                    {isInCart ? `X ${amount} in Korb` : "In den Korb"}
                  </button>
                  {isInCart && (
                    <button
                      type="button"
                      className="btn btn-primary btn-shadow btn-icon"
                      onClick={() => {
                        increaseAmount(product?.id);
                      }}
                    >
                      <i className="czi-arrow-up"></i>
                    </button>
                  )}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
      {/* only on large screen */}
      <section className="mb-4 mb-lg-5 d-block d-sm-none">
        {/* <!-- Nav tabs--> */}
        <ul className="nav nav-tabs ">
          <li className="nav-item">
            <a
              className="nav-link px-4 active"
              href="#details2"
              data-toggle="tab"
              role="tab"
            >
              {categoryName}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link px-4"
              href="#mehr2"
              data-toggle="tab"
              role="tab"
            >
              Mehr
            </a>
          </li>
        </ul>
        <div className="tab-content pt-2">
          {/* <!-- Product details tab--> */}
          <div
            className="tab-pane fade show active"
            id="details2"
            role="tabpanel"
          >
            <div className="row">
              <div className="col-lg-12">
                <p className="font-size-md">{categoryDescription}</p>
              </div>
            </div>
          </div>
          <div className="tab-pane fade show" id="mehr2" role="tabpanel">
            <div className="row">
              <div className="col-lg-12">
                <p className="font-size-md">{categoryLongText}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProductDetails;
