import Carousel from "react-multi-carousel";
import ImageSm from "../ImageSm";
import { LightgalleryItem } from "react-lightgallery";
import React from "react";
function CarouselPictures({ images, captions, group }) {
  const items = images.map((image, i) => {
    return (
      <LightgalleryItem group={group} src={`/img/${image}`} key={`img${i}`}>
        <ImageSm filename={image} caption={captions[i]} />
      </LightgalleryItem>
    );
  });
  return (
    <Carousel
      autoPlay={false}
      additionalTransfrom={0}
      centerMode={false}
      className="rounded"
      containerClass="container"
      dotListClass=""
      draggable
      swipeable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={50}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        mobile: {
          breakpoint: { max: 375, min: 0 },
          items: 1,
        },
      }}
      showDots={false}
      arrows={true}
      sliderClass=""
      slidesToSlide={1}
    >
      {items}
    </Carousel>
  );
}

export default CarouselPictures;
