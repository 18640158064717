import "lightgallery.js/dist/css/lightgallery.css";
import "../styles/gallery.css";

import ImageSm from "./ImageSm";
import { LightgalleryItem } from "react-lightgallery";
import React from "react";

function Gallery({ images, captions }) {
  let header = images[0];
  return header ? (
    <div className="cz-gallery " lg-uid="lg0">
      <LightgalleryItem group={`group`} src={`/img/${header}`}>
        <div className="gallery-item rounded-lg mb-grid-gutter">
          <ImageSm filename={header} alt="Gallery preview 1" />
          <span className="gallery-item-caption">{captions[0]}</span>
        </div>
      </LightgalleryItem>
      {images.length > 1 && <LightgalleryItem group={`group`} src={`/img/${images[1]}`} />}
      {images.length > 1 ? (
        <div className="rowminus" id="">
          <div className="col-sm-6">
            <LightgalleryItem group={`group`} src={`/img/${images[2]}`}>
              <div className="gallery-item rounded-lg mb-grid-gutter">
                <ImageSm filename={images[2]} alt="Gallery preview 2" />
                <span className="image-caption">{captions[2]}</span>
              </div>
            </LightgalleryItem>
          </div>
          <div className="col-sm-6">
            <LightgalleryItem group={`group`} src={`/img/${images[3]}`}>
              <div className="gallery-item rounded-lg mb-grid-gutter">
                <ImageSm filename={images[3]} alt="Gallery preview 3" />
                <span className="image-caption">{captions[3]}</span>
              </div>
            </LightgalleryItem>
          </div>
          {images.map((image, i) => {
            if (i > 3) {
              return <LightgalleryItem key={`img${i}`} group={`group`} src={`/img/${images[i]}`} />;
            }
            return true;
          })}
        </div>
      ) : null}
    </div>
  ) : (
    <div></div>
  );
}

export default Gallery;
