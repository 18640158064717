import React, { useContext, useEffect, useState } from "react";

import { CartContext } from "../cartContext";

function Marmelades({ marmelades, id, handleClick, selectMarmelades }) {
  const [selection, setSelection] = useState(selectMarmelades);
  const { inCart } = useContext(CartContext);
  const isInCart = inCart(id);

  useEffect(() => {
    if (selectMarmelades.reduce((a, b) => a + b, 0) > 0) {
      setSelection(selectMarmelades);
    } else {
      setSelection([0, 0]);
    }
  }, []);

  const handleChange = (value) => {
    let tempSelection = [...selection];
    tempSelection.shift();
    tempSelection.push(value);
    setSelection(tempSelection);
    handleClick(tempSelection);
  };
  return (
    <div className="bg-secondary rounded  w-100 p-3 mb-2">
      <div className="mr-3 mb-1 pt-1 ">
        <h5 className="row pb-2">Marmeladen (2 aussuchen) </h5>
        {marmelades.map((m, i) => (
          <button
            key={`jam${i}`}
            disabled={isInCart}
            className={
              selection[0] === m.marmelade.id || selection[1] === m.marmelade.id
                ? "btn btn-primary btn-sm pr-3 py-2 mb-2 mr-2 active"
                : "btn btn-primary btn-sm pr-3 py-2 mb-2 mr-2"
            }
            onClick={() => handleChange(m.marmelade.id)}
          >
            {selection[0] === m.marmelade.id ? (
              <span className="czi-check text-white " style={{ position: "relative", top: 0, left: 5 }}></span>
            ) : null}
            {selection[1] === m.marmelade.id ? <span className="czi-check text-white "></span> : null}
            <span className="ml-2"> {m.marmelade.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Marmelades;
