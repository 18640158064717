import { Link, graphql } from "gatsby";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import CarouselCategory from "../components/carousels/CarouselCategory";
import { LightgalleryProvider } from "react-lightgallery";
import ProductDetails from "../components/ProductDetails2";
import React from "react";
import slugish from "../../slugify";

const SingleProduct = ({ data, pageContext }) => {
  if (data === null)
    return (
      <section className="container p-0 p-sm-4">
        error loading product - check your connection
      </section>
    );
  const product = data?.hasura?.products_by_pk;

  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const slug = data?.hasura?.products_by_pk?.name;
  const categories = data?.hasura?.categories;
  const thisCategory = product?.categoryByCategory;
  const findRelatedCategory = (categories, thisCategory) => {
    const relations = [
      { origin: 1, relation: 2 },
      { origin: 2, relation: 3 },
      { origin: 3, relation: 4 },
      { origin: 4, relation: 5 },
      { origin: 5, relation: 3 },
      { origin: 6, relation: 4 },
      { origin: 7, relation: 3 },
      { origin: 100, relation: 3 },
    ];
    const relatedId = relations.find(
      (item) => item?.origin === thisCategory?.id
    );

    const relatedCategory = categories.find(
      (item) => item.id === relatedId?.relation
    );
    return relatedCategory;
  };
  const relatedCategory = findRelatedCategory(categories, thisCategory);

  return (
    <>
      <div>
        <LightgalleryProvider
          lightgallerySettings={{
            // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
            download: false,
          }}
          galleryClassName="cz-gallery"
        >
          <div className="page-title-overlap bg-accent pt-2">
            <div className="container d-lg-flex justify-content-between py-4 py-lg-3">
              <div className="order-lg-2 mb-0 mb-lg-0 pt-lg-2 breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center">
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=""
                  crumbLabel={slug}
                />
              </div>
            </div>
          </div>
          <section className="container p-0 p-sm-4">
            <ProductDetails product={product} />
            {thisCategory?.products.length < 2 ? null : (
              <>
                <Link to={`/produkte/${thisCategory?.slug}`}>
                  <h2 className="h3 pt-3 pb-0 text-center border-bottom">{`Alle ${thisCategory?.name}`}</h2>
                </Link>
                <CarouselCategory category={thisCategory} />
              </>
            )}

            <h2 className="h3 pt-3 pb-0 text-center border-bottom">
              Interessante Produkte für dich
            </h2>
            <CarouselCategory category={relatedCategory} />
          </section>
        </LightgalleryProvider>
      </div>
    </>
  );
};

export const query = graphql`
  query SingeProduct($id: Int!) {
    hasura {
      categories {
        id
      }
      products_by_pk(id: $id) {
        id
        ingredients
        description
        allergenes
        categoryByCategory {
          id
          name
          slug
          description
          longtext
          products {
            id
          }
          marmeladesByCategories {
            marmelade {
              id
              name
            }
          }
        }
        image
        poster
        captions
        name
        price
        productsOptions(distinct_on: option_id) {
          subOption {
            guests
            price
            name
          }
          option {
            id
            name
            description
            image
            productsOptions(order_by: { subOption: { price: asc } }) {
              subOption {
                id
                name
                description
                price
                guests
              }
            }
          }
        }
      }
    }
  }
`;

export default SingleProduct;

export const Head = ({ data }) => {
  const product = data?.hasura?.products_by_pk;
  const name = slugish(product?.name);
  const thisCategory = product?.categoryByCategory;
  let imgsplit = product?.image.split(",");
  let ogimage = imgsplit.length > 0 ? imgsplit : [product?.image];
  const OGIMAGE = `https://www.punsch-mobil.at/img/${ogimage}`;
  const TITLE = product?.name;
  const DESCRIPTION = product?.description;
  const PAGEURL = `https://punsch-mobil.at/produkte/${thisCategory.slug}/${name}`;
  return (
    <>
      <title>{product?.title}</title>
      <link
        rel="canonical"
        href={`https://punsch-mobil.at/produkte/${thisCategory.slug}/${name}`}
      />
      <meta name="description" content={DESCRIPTION} />
      <meta
        name="keywords"
        content={[
          "punsch lieferung",
          "crepes lieferservice",
          "lieferung",
          "wien",
          "punsch mobil",
          "punsch lieferservice",
          "punsch zustellung",
        ]}
      />
      <meta property="og:url" content={PAGEURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:image" content={OGIMAGE} />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:site"
        content={"lacreperiemobile" && `@lacreperiemobile`}
      />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content={OGIMAGE} />
      <script type="application/ld+json">
        {`
  {
    "@context": "https://schema.org",
    "@type": "Product",
    "description": "${product?.description}",
    "name": "${product?.name}",
    "image": "https://www.punsch-mobil.at/img/${product?.poster}",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "${product?.price}",
      "priceCurrency": "EUR"
    }
  }
`}
      </script>
    </>
  );
};
